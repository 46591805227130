import React, { useContext, useEffect, useState } from "react";
import useRenderOnDemand from "../../utils/useRenderOnDemand";
import { ImageFormat, ImageType, ImageStatus, ImageRequest } from "../../utils/useRenderOnDemand.types";
import useRenderConfig from "../../utils/useRenderConfig";
// import { DataContainerContext } from "../../common/context/DataContainerProvider";
import DataContainer from "../../services/DataContainer";
import { useWrap } from "../../utils/useWrap";
import SliderTpl from "./SliderTpl";
import { MarketSettings, RodSettings } from "../../enum/enums";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { useParams } from "react-router-dom";
interface SliderProps{
  onSummary?: boolean;
  isMediumDuty: boolean
}

const Slider = ({onSummary, isMediumDuty}: SliderProps) => {
  const [page, setPage] = useState<number>(0);
  const [images, setImages] = useState<string[]>(["", "", "", "", "", ""]);
  const [hasTimedOut, setHasTimedOut] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentProduct, setCurrentProduct] = useState<any>({});
  const [currentSource, setCurrentSource] = useState("");
  const {activeComponent, activeComponentGroups} = useContext(DataContainerContext);
  const exteriorViewPoints = DataContainer.getProductViewPoints() && DataContainer.getProductViewPoints().map((vp: { name: string }) => vp.name);
  const {modelName} = useParams();
  const {modelNameFromUrl} = useParams();

  useEffect(() => {
    setCurrentProduct(DataContainer.mLoadedData);
  },[DataContainer.mLoadedData]);
  
  const paginate = (newDirection: number) => {
    if (exteriorViewPoints)  setPage(useWrap(0, exteriorViewPoints.length - 1, page + newDirection));
  };
  
  const steeringwheel_position = DataContainer.sectionKey(MarketSettings.MARKET_SETTINGS, MarketSettings.STEERINGWHEEL_POSITION);  

  const renderConfig = useRenderConfig();
  const [currentViewpoint, setCurrentViewpoint] = useState(`${exteriorViewPoints[page]}_${steeringwheel_position.toUpperCase()}`);
  useEffect(() => {
    if (activeComponentGroups.name === "Interior") {
      if(activeComponent.name === "Int_trim_levels") {
        if(isMediumDuty) {
          setCurrentViewpoint(`interior_driverView_${renderConfig.productName}_${steeringwheel_position.toUpperCase()}`);
        } else {
          setCurrentViewpoint("driverView");
        }
      }
      else if(activeComponent.name === "Seats") {
        if(isMediumDuty) {
          setCurrentViewpoint(`interior_bedView_${renderConfig.productName}_${steeringwheel_position.toUpperCase()}`);
        } else {
          setCurrentViewpoint("bedView");
        }
      }
    } else {
      setCurrentViewpoint(`${exteriorViewPoints[page]}_${steeringwheel_position.toUpperCase()}`);
    }

    if(onSummary) {
      setCurrentViewpoint(`${exteriorViewPoints[page]}_${steeringwheel_position.toUpperCase()}`);
    }
  }, [activeComponent, activeComponentGroups, page]);
  
  const renderSpec: ImageRequest = {
    sequence: currentViewpoint,
    attributes: { 
      steeringwheel_position: steeringwheel_position,
      stage: "studio"
    },
    renderVersion: parseInt(DataContainer.sectionKey(RodSettings.ROD_SETTINGS, `${modelName ? modelName : modelNameFromUrl}_renderversion`)),
    configuration: renderConfig,
    format: ImageFormat.JPEG,
    width: 1920,
    height: 1080,
    type: ImageType.Image,
    compressionQuality: 100,
  };
  
  const imageInformation = useRenderOnDemand(renderSpec);  
  
  const hash = JSON.stringify(renderConfig);
  const anglesHash = exteriorViewPoints && exteriorViewPoints.join(",");    

  useEffect(() => {
    setIsLoading(true);
    if (imageInformation.status === ImageStatus.Ready) {
      setIsLoading(false);
    }
  }, [imageInformation]);

  useEffect(() => {
    // Reset whenever the configuration changes
    setImages(exteriorViewPoints.map(() => ""));
  }, [hash]);

  useEffect(()=> {
    setCurrentSource(imageInformation.urlAssets[0]);
  },[imageInformation]);
  

  // //*************This piece of code is not necessary anymore(left here if any changes needed)********************
  // useEffect(() => {
  //   if(!images[page]){
  //     const asset = imageInformation.urlAssets && imageInformation.urlAssets[0];
  //     setCurrentSource(imageInformation.urlAssets[0]);
  //     if (asset && images[page] != asset) {
  //       setImages((old) => {
  //         const newImages = [...old];              
  //         newImages[page] = asset;              
  //         return newImages;
  //       });
  //     }
  //   } else {
  //     // if(activeComponentGroups.name === "Interior") {
  //     //   imageInformation.urlAssets[0] && setCurrentSource(imageInformation.urlAssets[0]);
  //     // }
  //     // setCurrentSource(imageInformation.urlAssets[0])
  //     setCurrentSource(images[page]);
  //   }
      
  // *************************************************************
  useEffect(() => {
    if (imageInformation.status === ImageStatus.Timeout) {
      setHasTimedOut(true);
    } else if (hasTimedOut) {
      setHasTimedOut(false);
    }
  }, [imageInformation]);

  useEffect(() => {
    setPage(0);
  }, [anglesHash]);

  return (
    <SliderTpl
      page={page}
      setPage={setPage}
      paginate={paginate}
      hash={hash}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      currentSource={currentSource}
      exteriorViewPoints={exteriorViewPoints}
      onSummary={onSummary}
      showError={hasTimedOut}
      rodResponseError={imageInformation.error}
    />
  );
};

export default Slider;
